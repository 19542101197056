.App {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
  }
  :root {
    --bodybg: #2c3e50;
    --darkbg: #1d2935;
    --lightbg: #3a5168;
    --lightestbg: #6c95be;
    --themecolor: #07100e;
    --textcolor: #ecf0f1;
    --headfont: "EB Garamond", serif;
    --shadegreen: #28f09d;
    --shadeblue: #06abee;
    --darkest: #121920;
  }
  
  html {
    font-size: 62.5%;
    scroll-behavior: smooth;
  }
  
  body {
    background-color: var(--bodybg);
    padding: 0;
    margin: 0;
    cursor: url(./img/cursor.ico), auto;
  }
  main {
    width: clamp(300px, 95%, 1300px);
    margin: auto;
    min-height: 100vh;
    text-align: center;
  }
  a {
    color: var(--textcolor);
    text-decoration: none;
  }
  li {
    text-decoration: none;
    list-style: none;
  }
  p {
    margin: 0;
  }
  section{
    margin: 4rem auto;
  }
  /* THIS CSS IS FOR BTN 1  */
  
  .btn1 {
    width: clamp(4rem, 90%, 25rem);
    appearance: button;
    background-color: var(--lightbg);
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: din-round, sans-serif;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 20px;
    margin: 0 auto;
    outline: none;
    overflow: visible;
    padding: 13px 16px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transform: translateZ(0);
    transition: filter 0.2s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
  }
  
  .btn1:after {
    background-clip: padding-box;
    background-color: var(--lightestbg);
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    bottom: -4px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
  
  .btn1:main,
  .btn1:focus {
    user-select: auto;
  }
  
  .btn1:hover:not(:disabled) {
    filter: brightness(1.1);
  }
  
  .btn1:disabled {
    cursor: auto;
  }
  
  /* THIS IS CSS FOR BTN 2  */
  
  .btn2 {
    background-image: linear-gradient(144deg, var(--shadeblue), var(--shadegreen));
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #ffffff;
    font-family: Phantomsans, sans-serif;
    font-size: 2rem;
    justify-content: center;
    line-height: 1em;
    padding: 1rem;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    margin: 1rem 2rem 1rem 0;
  }
  
  .btn2:active,
  .btn2:hover {
    outline: 0;
  }
  
  .btn2 span {
    background-color: rgb(5, 6, 45);
    padding: 1rem 2rem;
    border-radius: 6px;
    transition: 300ms;
  }
  
  .btn2:hover span {
    background: none;
  }
  
  @media (min-width: 768px) {
    .button-64 {
      font-size: 24px;
    }
  }
  
  .h2 {
    background-image: -webkit-gradient(linear, left top, left bottom, from(var(--shadegreen)), to(var(--shadeblue)));
    background-image: linear-gradient(var(--shadegreen), var(--shadeblue));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: var(--headfont);
    align-items: center;
    font-size: 3rem;
  }
  .h3{
    color: var(--textcolor);
    font-size: 2rem;
  }
  hr {
    color: var(--darkbg);
    margin: 2rem auto;
  }
  .hide {
    display: none;
  }
  
  .alertbox{
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    background-color: transparent #1d2935;
  }
  .alertbox div{
    width: 200px;
    margin: auto;
    background-color: var(--lightbg);
    padding: 4rem;
    border: 2px solid var(--lightestbg);
    border-radius: 2rem;
    align-self: center;
    filter: opacity(1) !important;
  }
  .alertbox p{
    font-size: 2rem;
    color: var(--lightestbg);
  }
  



  
/* DELETE FORM CSS  */
.DeleteForm {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: clamp(100px, 98%, 900px);
    margin: auto;
  }
  .deletecard {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 2rem auto;
    padding: 1rem;
    border-radius: 1rem;
    background: var(--lightbg);
  }
  .deletecard h1 {
    color: var(--textcolor);
  }




  

 /* ADMIN CSS  */
 .adminaside{
    display: flex;
    justify-content: space-between;
    width: clamp(300px,100%,900px);
    margin: auto;
    background-color: var(--darkest);
    border-radius: 3rem;
  }
  .adminaside a{
    width: clamp(10px, 100%, 50px);
  }
  .adminaside img{
   width: clamp(10px, 100%, 50px);
  }
 
  /* SIGN IN UP CSS IS HERE  */
  .Sign{
    background-color: var(--darkbg);
    width: clamp(500px, 100%, 900px);
    margin: 4rem auto;
    padding: 2rem;
    border-radius: 2rem;
    text-align: center;
  }
  .Sign form{
    display: flex;
    flex-direction: column;
    height: 200px;
  }
  .Sign input {
   padding: 1.5rem;
   width: clamp(300px, 90%, 500px);
   margin: auto;
   border-radius: 1rem;
   border: none;
   background-color: var(--lightbg);
   font-size: 1.8rem;
   color: var(--textcolor);
 }
 


 /* ADMIN CSS  */
 .adminaside{
  display: flex;
  justify-content: space-between;
  width: clamp(300px,100%,900px);
  margin: auto;
  background-color: var(--darkest);
  border-radius: 3rem;
}
.adminaside a{
  width: clamp(10px, 100%, 50px);
}
.adminaside img{
 width: clamp(10px, 100%, 50px);
}


.logo {
  text-align: center;
  width: 100%;
  margin: auto;
}

.logo h1 {
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--shadegreen)), to(var(--shadeblue)));
  background-image: linear-gradient(var(--shadegreen), var(--shadeblue));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--headfont);
  align-items: center;
}

.logo img {
  padding: 1rem 2rem;
  width: 7rem;
  height: 5.7rem;
}



/* FORM FOR NEW GAME CSS  */
.InsertFormGame {
  display: flex;
  flex-direction: column;
  width: clamp(300px, 90%, 900px);
  margin: 5rem auto;
  font-size: 2rem;
  color: var(--textcolor);
}

.InsertFormGame input {
  padding: 1.5rem;
  border-radius: 1rem;
  border: none;
  background-color: var(--lightbg);
  font-size: 1.8rem;
  color: var(--textcolor);
}

.InsertFormGame label {
  text-align: left;
  font-size: 3rem;
  color: var(--textcolor);
  margin-top: 4rem;
  margin-bottom: 0.5rem;
}
.InsertFormGame select {
  padding: 1.5rem;
  border-radius: 1rem;
  border: none;
  background-color: var(--lightbg);
  font-size: 1.8rem;
  color: var(--textcolor);
}
.InsertFormGame button {
  margin: 5rem auto;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--shadegreen)), to(var(--shadeblue)));
  background-image: linear-gradient(var(--shadegreen), var(--shadeblue));
  border: none;
  padding: 1rem 5rem;
  border-radius: 1rem;
  font-size: 2rem;
  color: #1d2935;
  transition: 0.5s ease-in-out;
}
.InsertFormGame button:hover {
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
}
.InsertFormGame button:active {
  transform: scale(1);
  transition: 0.5s ease-in-out;
}
.InsertFormGame fieldset {
  border: none;
  display: flex;
  justify-content: space-evenly;
  width: 300px;
}
